import React from "react";
import "./gallery.scss";

interface Props {
  backgroundColor: string;
  color: string;
  children: any;
  frontmatter: any;
  style?: {};
}

const Gallery = (props: Props) => {
  let gridClass = "";
  switch (props.children.length) {
    case 1:
      break;
    case 2:
      gridClass = "grid-2";
      break;
    case 3:
      gridClass = "grid-3";
      break;
    case 4:
      gridClass = "grid-4";
      break;
    case 5:
      gridClass = "grid-5";
      break;
    case 6:
      gridClass = "grid-6";
      break;
    case 7:
      gridClass = "grid-7";
      break;
    default:
      break;
  }
  const baseStyle = { backgroundColor: props.backgroundColor };
  let style = props.style
    ? Object.assign({ baseStyle }, props.style)
    : Object.assign({ baseStyle });
  return (
    <div className={`gallery ${gridClass}`} style={style}>
      {console.log("Style", style)}
      {props.children}
    </div>
  );
};
Gallery.defaultProps = {
  backgroundColor: "inherit",
  color: "inherit",
  style: {},
};
export default Gallery;
