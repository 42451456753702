/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import WebsiteIcon from "../../images/offer/service_icon_website.inline.svg";
import DesignIcon from "../../images/offer/service_icon_design.inline.svg";
import InfrastructureIcon from "../../images/offer/service_icon_infrastructure.inline.svg";
import MobileIcon from "../../images/offer/service_icon_mobile.inline.svg";
import SoftwareIcon from "../../images/offer/service_icon_software.inline.svg";
import ECommerce from "../../images/offer/service_icon_e-commerce.inline.svg";
interface Props {
  category: string;
  color?: string;
  full?: boolean;
}

class CategoryIcon extends React.Component {
  src: any | null = null;
  description = "";
  static defaultProps = {
    full: false,
  };

  constructor(public props: Props) {
    super(props);
    const style: { [key: string]: any } = {
      marginBottom: 0,
      height: "4em",
      width: "4em",
    };
    if (typeof this.props.color === "string") {
      style.fill = this.props.color;
    }
    switch (props.category) {
      case "website":
        this.src = (
          <WebsiteIcon className="inline" {...this.filteredProps()} style={style}></WebsiteIcon>
        );
        this.description = "Strona internetowa";
        break;
      case "design":
        this.src = (
          <DesignIcon className="inline" {...this.filteredProps()} style={style}></DesignIcon>
        );
        this.description = "Design";
        break;
      case "infrastructure":
        this.src = <InfrastructureIcon  className="inline" {...this.filteredProps()} style={style}></InfrastructureIcon>;
        this.description = "Administracja";
        break;
      case "mobile":
        this.src = <MobileIcon  className="inline" {...this.filteredProps()} style={style}></MobileIcon>;
        this.description = "Aplikacje mobilne";
        break;
      case "software":
        this.src = <SoftwareIcon  className="inline" {...this.filteredProps()} style={style}></SoftwareIcon>;
        this.description = "Aplikacje webowe";
        break;
      case "e-commerce":
        this.src = <ECommerce  className="inline" {...this.filteredProps()} style={style}></ECommerce>;
        this.description = "Sklepy internetowe";
        break;

      default:
        break;
    }
  }
  filteredProps() {
    // let className = `button button-${this.props.color} button-${this.props.type}`;

    const filteredProps = { ...this.props };
    delete filteredProps.full;
    delete filteredProps.color;
    delete filteredProps.category;
    // if (typeof filteredProps.className === "string") {
    //   className = filteredProps.className + " " + className;
    // }
    // filteredProps.className = className;
    return filteredProps;
  }
  render() {
    return (
      <div
        style={{
          display: "inline-block",
          fontSize: "0.8em",
          textAlign: "center",
          marginRight: "1em",
        }}
      >
        {this.src}
        {this.props.full ? (
          <p
            style={{ lineHeight: "1em", marginTop: "0em", marginBottom: "0em" }}
          >
            {this.description}
          </p>
        ) : null}
      </div>
    );
  }
}

export default CategoryIcon;
