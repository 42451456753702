import React, { useEffect, useRef } from "react";
import SEO from "../../components/seo/seo";
import { PostPageContext, PageParams } from "../../../types";
import Footer from "../../components/footer/footer";
import Hero from "../../components/hero/hero";
import SvgImage from "../../components/svg-image/svg-image";
import CategoryIcon from "../../components/category-icon/category-icon";
import CarnetReportSVG from "../../images/carnet/reports.inline.svg";
import GooglePlaySVG from "../../images/svg/google_play.inline.svg";
import AppStoreSVG from "../../images/svg/app_store.inline.svg";
import BackgroundImage from "gatsby-background-image";
import CallFooter from "../../components/call-footer/call-footer";
import Section from "../../components/air-section/air-section";
import Challenge from "./challenge";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Img from "gatsby-image";
import Gallery from "./gallery";
import PortfolioList from "../../components/portfolio-list/portfolio-list";
import Reference from "./reference";
import ReferencesSlider from "./references-slider";
import HeaderBig from "../../components/header-big/header-big";
import { GlobalState } from "../../components/layout/global-state";
import { Video } from "../../components/video/video";
import ScrollButton from "../../components/scroll-button/scroll-button";
const shortcodes = {
  Section,
  Challenge,
  Reference,
  Gallery,
  Footer,
  Img,
  Video,
  CarnetReportSVG,
  GooglePlaySVG,
  AppStoreSVG,
  SvgImage,
  BackgroundImage,
  ReferencesSlider,
};
const PortfolioItemPage = (params: PageParams<PostPageContext>) => {
  const language = params.pageContext.language;
  const node = params.pageContext.node;
  const items = params.pageContext.nodes;
  const frontmatter = { ...node.childMdx.frontmatter };

  console.log("node", node);
  console.log("items", items);
  console.log("frontmatter", frontmatter);
  const heroColor =
    typeof frontmatter.hero_color === "string"
      ? frontmatter.hero_color
      : "inherit";
  const heroColorShadow =
    typeof frontmatter.hero_color_shadow === "string"
      ? frontmatter.hero_color_shadow
      : "inherit";
  let categories = node.childMdx.frontmatter.category;
  let gallery = node.childMdx.frontmatter.gallery
    ? [...node.childMdx.frontmatter.gallery]
    : [];
  gallery = gallery.map((galleryItem: any, index: number) => {
    return {
      fluid: galleryItem.childImageSharp.fluid,
      alt: node.childMdx.frontmatter.gallery_alt[index],
    };
  });
  if (heroColor !== "inherit") {
    GlobalState.set({ headerColor: heroColor });
  }
  if (!categories) {
    categories = [];
  }
  frontmatter.gallery = gallery;
  const scrollRef = useRef();
  useEffect(() => {
    return () => {
      GlobalState.set({ headerColor: null });

      // Anything in here is fired on component unmount.
    };
  }, []);

  return (
    <>
      <SEO
        title={frontmatter.short_description + " " + frontmatter.title}
        hasHeroImage={true}
        description={frontmatter.description + " Aplinet ☎ +48 665 035 451"}
        lang={language}
      />
      <BackgroundImage
        tag="div"
        className="brand-bg fennec-color"
        fluid={gallery.length ? gallery[0].fluid : null}
        style={{ height: "auto" }}
      >
        <Hero>
          <div className="container-xs header-padding">
            <h1
              style={{ color: heroColor, textShadow: heroColorShadow }}
              dangerouslySetInnerHTML={{ __html: frontmatter.title }}
            ></h1>
            <div className="header-physical-subline">
              <h2
                className="subtitle"
                style={{ color: heroColor, textShadow: heroColorShadow }}
                dangerouslySetInnerHTML={{ __html: frontmatter.description }}
              ></h2>
              <div
                className="subline"
                style={{ backgroundColor: heroColor }}
              ></div>
            </div>
            <div className="r :a-rt">
              {categories.map((item: string, index: number) => {
                return (
                  <CategoryIcon
                    key={index}
                    category={item}
                    color={heroColor}
                    full={false}
                  ></CategoryIcon>
                );
              })}
            </div>
          </div>
          <ScrollButton
            color={frontmatter.section_bg}
            style={{
              position: "absolute",
              backgroundColor: frontmatter.color,
              left: "50%",
              bottom: "5%",
              transform: "translateX(-50%)",
            }}
            to={scrollRef}
          />
        </Hero>
      </BackgroundImage>

      <section ref={scrollRef}>
        <MDXProvider components={shortcodes}>
          <MDXRenderer scope={{ frontmatter }}>
            {node.childMdx.body}
          </MDXRenderer>
        </MDXProvider>
      </section>
      <Section>
        <div className="container">
          <HeaderBig
            span="Zobacz"
            headerText="Inne realizacje"
            tag="h2"
          ></HeaderBig>
        </div>
        <div className="container">
          <PortfolioList items={frontmatter.related}></PortfolioList>
        </div>
      </Section>
      <CallFooter lang={language} />
    </>
  );
};

export default PortfolioItemPage;
