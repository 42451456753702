import React from "react";

interface Props {
  backgroundColor: string;
  color: string;
  children: any;
  frontmatter: any;
}

const Challenge = ({
  backgroundColor,
  color,
  children,
  frontmatter,
}: Props) => {
  return (
    <section
      className="challenge section"
      style={{ backgroundColor: backgroundColor, color: color }}
    >
      <div className="container-xs">{children}</div>
    </section>
  );
};
Challenge.defaultProps = {
  color: "inherit",
};
export default Challenge;
