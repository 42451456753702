import React from "react";

interface Props {
  name: string;
  position: string;
  children: any;
  color: string;
  backgroundColor: string;
}

const Reference = ({
  name,
  position,
  children,
  backgroundColor,
  color,
}: Props) => {
  const cornerSize = "2em";
  return (
    <section>
      <h2 style={{ textAlign: "center" }}>Opinia klienta</h2>
      <div className="reference section" style={{ backgroundColor }}>
        <div className="container-xs">
          <div
            className="reference-content"
            style={{
              maxWidth: "750px",
              margin: "0 auto",
              padding: cornerSize,
              position: "relative",
              background: "white",
            }}
          >
            <div
              className="top-left"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                borderRight: `${cornerSize} solid white`,
                borderTop: `${cornerSize} solid ${backgroundColor}`,
                width: 0,
              }}
            ></div>
            <div
              className="bottom-right"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                borderLeft: `${cornerSize} solid white`,
                borderBottom: `${cornerSize} solid ${backgroundColor}`,
                width: 0,
              }}
            ></div>
            {children}

            <p className="signature" style={{ marginBottom: 0 }}>
              <span
                className="person"
                style={{ color: color, fontWeight: 500 }}
              >
                {name}
              </span>
              <br />
              <span
                className="position"
                style={{
                  fontFamily: '"Merriweather", sans-serif',
                  fontStyle: "italic",
                  fontSize: "0.9em",
                }}
              >
                {position}
              </span>
              <br />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
Reference.defaultProps = {
  color: "#50817b",
  backgroundColor: "#f5f5f5",
};
export default Reference;
