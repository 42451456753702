import React from "react";
import Slider from "react-slick";

interface boxItemProps {
  position?: string;
  name?: string;
  company?: string;
  description?: string;
}

interface Props {
  name: string;
  position: string;
  children: any;
  boxItems: boxItemProps[];
  color: string;
  backgroundColor: string;
}

const ReferencesSlider = ({
  name,
  position,
  children: any,
  boxItems,
  color,
  backgroundColor,
}: Props) => {
  const cornerSize = "2em";
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchThreshold: 25,
    pauseOnFocus: true,
  };

  return (
    <>
      <section className="reference section" style={{ backgroundColor }}>
        <div className="references-wrapper">
          <div className="container-xs">
            <Slider {...settings}>
              {boxItems.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="item"
                    style={{
                      padding: cornerSize,
                    }}
                  >
                    <div
                      className="reference-content"
                      style={{
                        position: "relative",
                      }}
                    >
                      {item.description && (
                        <p
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        ></p>
                      )}
                      <div className="reference-footer r :c-12 :c-6:xs+ :a-ce">
                        <div
                          className="signature"
                          style={{ lineHeight: "1.1em" }}
                        >
                          {item.name && (
                            <span
                              className="person"
                              style={{
                                lineHeight: "1.7em",
                                color: color,
                                marginBottom: 0,
                              }}
                              dangerouslySetInnerHTML={{
                                __html: item.name,
                              }}
                            ></span>
                          )}
                          <br />
                          {item.position && (
                            <span
                              className="position"
                              dangerouslySetInnerHTML={{
                                __html: item.company
                                  ? item.position + ", "
                                  : item.position,
                              }}
                              style={{
                                fontFamily: '"Merriweather", sans-serif',
                                fontStyle: "italic",
                                fontSize: "0.8em",
                              }}
                            ></span>
                          )}
                          <br />
                          {item.company && (
                            <span
                              className="position"
                              dangerouslySetInnerHTML={{
                                __html: item.company,
                              }}
                              style={{
                                fontFamily: '"Merriweather", sans-serif',
                                fontStyle: "italic",
                                fontSize: "0.8em",
                              }}
                            ></span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};
ReferencesSlider.defaultProps = {
  color: "#50817b",
  backgroundColor: "#f5f5f5",
};
export default ReferencesSlider;
