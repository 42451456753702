import React from "react";
import Img from "gatsby-image";

import "./portfolio-list.scss";
import ALink from "../a-link/a-link";

interface Props {
  items: any[];
}

class PortfolioList extends React.Component {
  static defaultProps = {
    items: [],
  };
  constructor(public props: Props) {
    super(props);
  }

  render() {
    return (
      <section style={{ overflow: "hidden" }}>
        <div className="r :c-4 :c-6:md :c-6:sm :c-6:xs :c-12:xxs :w portfolio-list">
          {this.props.items.map((item, index) => {
            return (
              <div className="item p" key={index}>
                <ALink
                  className="corner-wrapper"
                  title={item.title}
                  to={item.url}
                  language={item.language}
                  style={{
                    display: "block",
                  }}
                >
                  <div className="border-top"></div>
                  <div className="border-bottom"></div>
                  <Img
                    fluid={item.thumbnail.childImageSharp.fluid}
                    alt={item.title}
                    title={item.title}
                    style={{ marginBottom: 0 }}
                  />
                </ALink>
                <ALink
                  title={item.title}
                  to={item.url}
                  language={item.language}
                  style={{
                    display: "block",
                    marginTop: "0.4em",
                  }}
                >
                  {item.short_description}
                </ALink>
                <ALink
                  title={item.title}
                  to={item.url}
                  language={item.language}
                >
                  <h3
                    className="title"
                    style={{ marginBottom: 0 }}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  ></h3>
                </ALink>
              </div>
            );
          })}
        </div>
      </section>
    );
  }
}

export default PortfolioList;
